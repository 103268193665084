// Bootstrap sizes as js objects

window.pjs = window.pjs || {};

window.pjs.breakpoints = {
  'screen': 'only screen',
  'landscape': 'only screen and (orientation: landscape)',
  'portrait': 'only screen and (orientation: portrait)',
  'xs-up': 'only screen',
  'xs-only': 'only screen and (max-width: 576px)',

  'sm-up': 'only screen and (min-width:577px)',
  'sm-down': 'only screen and (max-width:768px)',
  'sm-only': 'only screen and (min-width:577px) and (max-width:768px)',

  'md-up': 'only screen and (min-width:767px)',
  'md-down': 'only screen and (max-width:992px)',
  'md-only': 'only screen and (min-width:767px) and (max-width:992px)',

  'lg-up': 'only screen and (min-width:993px)',
  'lg-down': 'only screen and (max-width:1200px)',
  'lg-only': 'only screen and (min-width:993px) and (max-width:1200px)',

  'xl-up': 'only screen and (min-width:1201px)',
  'xl-down': 'only screen and (max-width:1440px)',
  'xl-only': 'only screen and (min-width:1201px) and (max-width:1440px)',

  'xxl-up': 'only screen and (min-width:1441px)',
  'xxl-only': 'only screen and (min-width:1441px) and (max-width:99999999px)'
};
