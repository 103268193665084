// Import components
import Header from './Components/Header';
import InPageNavigation from './Components/InPageNavigation';
import Selectric from './Components/Selectric';
import TabbedPanel from './Components/TabbedPanel';
import GoogleMap from './Components/Google/GoogleMap';
import GoogleMapStatic from './Components/Google/GoogleMapStatic';
import GoogleGeoCode from './Components/Google/GoogleGeoCode';
import Video from './Components/Video';
import LoadMore from './Components/LoadMore';
import FullList from './Components/FullList';
import ReadMore from './Components/ReadMore';

import './utils/breakpoints';

$(document).ready(() => {
  try {
    document.createEvent('TouchEvent');
    $('body').addClass('touch');
  } catch (e) {
    // nothing
  }

  // Initiate components
  Header.init();
  InPageNavigation.init();
  Selectric.init();
  TabbedPanel.init();
  // Assign components to window so they can be called anywhere. Not ideal, but it works
  window.GoogleMap = GoogleMap;
  window.GoogleMapStatic = GoogleMapStatic;
  window.GoogleGeoCode = GoogleGeoCode;

  window.Selectric = Selectric;
  Video.init();
  LoadMore.init();
  FullList.init();
  ReadMore.init();
});
