class LoadMore {
  constructor() {
    this.sel = {
      button: '.js-load-more-cards'
    };

    this.skip = parseInt($(this.sel.button).data('increment'), 10);

    this.bindEvents = this.bindEvents.bind(this);
    this.getMore = this.getMore.bind(this);
    this.processMore = this.processMore.bind(this);
    this.init = this.init.bind(this);
  }

  bindEvents() {
    $(this.sel.button).on('click', (e) => {
      e.preventDefault();
      let endpoint = $(e.target).data('endpoint').replace('{skip}', this.skip);
      this.getMore(endpoint, $(e.target));
    });
  }

  getMore(endpoint, target) {
    $.ajax({
      url: endpoint,
      type: 'get',
      dataType: 'json',
      success: (data) => {
        this.processMore(data, target);
      },
      error: (data) => {
        console.log(data); // eslint-disable-line no-console
      }
    });
  }

  processMore(data, target) {
    this.skip += data.pageSize;

    let html = data.result;

    let container = $('#' + target.data('container'));

    if (container.length) {
      container.append(html);

      if (!data.hasNextPage) {
        target.hide();
      }
    } else {
      target.hide();
    }
  }

  init() {
    if ($(this.sel.button).length <= 0) return;
    this.bindEvents();
  }
}

export default new LoadMore();
