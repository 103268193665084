import VideoYTPlayer from './VideoYTPlayer';
import VideoVimeoPlayer from './VideoVimeoPlayer';

class Video {
  constructor() {
    this.sel = {
      component: '.video'
    };
  }

  init() {
    if ($(this.sel.component).length <= 0) return;
    $(this.sel.component).each((index, element) => {
      const playerType = $(element).data('video-player');
      if (playerType === 'yt-player') {
        VideoYTPlayer.init();
      } else if (playerType === 'vimeo-player') {
        VideoVimeoPlayer.init();
      }
    });
  }
}

export default new Video();
