class LocationsAccordion {
  init() {
    this.sel = {
      component: '#locationsAccordion',
      items: '.accordion-item',
      itemContent: '.accordion-collapse'
    };
    if ($(this.sel.component).length <= 0) return;

    this.showAccordion = this.showAccordion.bind(this);
    this.showAllAccordions = this.showAllAccordions.bind(this);
  }

  showAccordion(index) {
    $(this.sel.component).find(this.sel.items).addClass('d-none');
    const $accordionToShow = $(this.sel.component).find(this.sel.items).eq(index);
    if ($accordionToShow) {
      $accordionToShow.removeClass('d-none');
      $accordionToShow.find(this.sel.itemContent).collapse('show');
    }
  }

  showAllAccordions() {
    $(this.sel.component).find(this.sel.items).removeClass('d-none');
    $(this.sel.component).find(this.sel.items + ' ' + this.sel.itemContent).collapse('hide');
  }
}

export default new LocationsAccordion();
