class Header {
  constructor() {
    this.sel = {
      component: '.header',
      nav: '.header__navigation',
      geolocation: '.header__geolocation',
      geoClose: '.header__geolocation-close',
      tabsOpen: '.header__helperbar--open',
      navStickyOpen: '.header__stickynav--open'
    };

    this.handleLevels = this.handleLevels.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.mobileNavMenu = this.mobileNavMenu.bind(this);
    this.helperBar = this.helperBar.bind(this);
    this.init = this.init.bind(this);
    this.selectedItem = this.selectedItem.bind(this);
    this.selectedNavItem = this.selectedNavItem.bind(this);
    this.stickyNav = this.stickyNav.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
  }

  bindEvents() {
    $(this.sel.geoClose).on('click', () => {
      $(this.sel.geolocation).hide();
      document.cookie = 'GeoBannerDismissed=1';
    });

    // language dropdown
    $('.header__language-toggle, .header__language-menu').on('click', (e) => {
      e.stopPropagation();
    });

    // hide / show promo panel on desktop
    $('.header__level1 > li a').on('click', (e) => {
      if (window.matchMedia('(min-width: 992px)').matches) {
        $(e.currentTarget).parent().parent().parent().siblings('.header__promoPanel').removeClass('d-lg-block');
      }
    });

    $('.nav-link.dropdown-toggle').on('click', () => {
      $(this.sel.geolocation).hide();
      document.cookie = 'GeoBannerDismissed=1';
      if (window.matchMedia('(min-width: 992px)').matches) {
        $('.header__promoPanel').addClass('d-lg-block');
      }
    });
  }

  helperBar() {
    $('.header__helperbar-tab.nav-link').on('click', () => {
      $('.header__helperbar-mobileMenuTab').removeClass('active');
      $('.tab-content').addClass('header__helperbar--active');
    });

    // side helper bar - desktop functionality
    $('.header__helperbar-tab').on('click', (e) => {
      e.preventDefault();
      if (window.matchMedia('(min-width: 992px)').matches) {
        const tab = $(e.currentTarget).parent();
        if (tab.hasClass('header__helperbar--open')) {
          $('.header__helperbar').removeClass('header__helperbar--active-bar');
          $(e.currentTarget).removeClass('active');
          tab.removeClass('header__helperbar--open');
        } else {
          $('.header__helperbar').addClass('header__helperbar--active-bar');
          $(this.sel.tabsOpen).removeClass('header__helperbar--open');
          tab.addClass('header__helperbar--open');
        }
      }
    });

    // side helper bar - mobile functionality
    $('.header__helperbar-mobileMenuTab').on('click', (e) => {
      $(e.currentTarget).addClass('active');
      $('.header__helperbar-tab.nav-link').removeClass('active');
      $('.tab-content').removeClass('header__helperbar--active');
    });
  }

  mobileNavMenu() {
    $('.navbar-toggler').on('click', () => {
      $('body').toggleClass('modal-open');
      $('.header__helperbar-mobileMenuTab').toggleClass('active');
      $('.tab-content').toggle();
      $('.tab-content').removeClass('header__helperbar--active');
      $(this.sel.geolocation).hide();
    });
  }

  handleLevels() {
    // first level
    $('.dropdown').on('hide.bs.dropdown', () => {
      $('.dropdown-submenu .dropdown-menu.show').removeClass('show');
    });

    // second level
    $('.dropdown-submenu').on('click', (e) => {
      let submenu = $(e.currentTarget).children('a');
      $('.dropdown-submenu .dropdown-menu').removeClass('show');
      submenu.next('.dropdown-menu').addClass('show');
      // The whole link needs to be clickable, rather then just the text.
      // Rather then re-engineer half of the HTML at a late stage, use some JS to click the child <a> tag
      // if clicking the parent <li> but only if no third level menu is required
      submenu.next('.dropdown-menu').find('li.dropdown-item').on('click', (a) => {
        const smenu = $(a.currentTarget).children('a')[0];
        if (smenu.classList.length === 0) {
          smenu.click();
          a.stopImmediatePropagation();
        }
      });
      e.stopImmediatePropagation();
    });

    // third level
    $('.dropdown-subSubmenu').on('click', (e) => {
      let subSubmenu = $(e.currentTarget).children('a');
      $('.dropdown-subSubmenu .dropdown-menu').removeClass('show');
      subSubmenu.next('.dropdown-menu').addClass('show');
      e.stopPropagation();
    });

    $('.mobile-eyebrow').on('click', (e) => {
      const smenu = $(e.currentTarget).children('a')[0];
      if ($(e.currentTarget).is('a')) {
        e.target.click();
        e.stopImmediatePropagation();
      } else {
        smenu.click();
        e.stopImmediatePropagation();
      }
    });
  }

  handleBack() {
    $('.header__back').on('click', (e) => {
      e.stopPropagation();
      $(e.currentTarget).parent().removeClass('show');
      $(e.currentTarget).parent().parent().find('.dropdown-toggle.show').removeClass('show');
    });

    $('.header__level2 .header__back, .header__level3 .header__back').on('click', (e) => {
      e.stopPropagation();
      $(e.currentTarget).closest('.dropdown-menu.show').removeClass('show');
    });
  }

  selectedItem() {
    $('.dropdown-submenu').on('click', (e) => {
      let subSubmenu = $(e.currentTarget).children('a');
      $('.dropdown-submenu.header__active-item').removeClass('header__active-item');
      subSubmenu.closest('.dropdown-submenu').addClass('header__active-item');
      subSubmenu.parent().parent().addClass('header__border-right');
    });

    $('.dropdown-subSubmenu').on('click', (e) => {
      let subSubmenu = $(e.currentTarget).children('a');
      $('.dropdown-subSubmenu.header__active-item').removeClass('header__active-item');
      subSubmenu.closest('.dropdown-subSubmenu').addClass('header__active-item');
      subSubmenu.parent().parent().addClass('header__border-right');
    });
  }

  selectedNavItem() {
    $('.header .nav-link').on('click', () => {
      $('.dropdown-submenu.header__active-item').removeClass('header__active-item');
      $('.dropdown-subSubmenu.header__active-item').removeClass('header__active-item');
    });
  }

  setNav(switchPoint) {
    if (window.scrollY >= (switchPoint.offset().top + switchPoint.outerHeight())) {
      $(this.sel.nav).addClass('header--sticky');
    } else {
      $(this.sel.nav).removeClass('header--sticky');
    }
  }

  // leaving this, but it causes flickers, remove it
  jumpsPrevent() {
    $(this.sel.nav).after('<div class="jumps-prevent"></div>');
    const stickyHeight = $(this.sel.nav).innerHeight();
    $(this.sel.nav).css({'margin-bottom': '-' + stickyHeight + 'px'});
    $(this.sel.nav).next().css({'padding-top': stickyHeight + 'px'});
  }

  stickyNav() {
    const switchPoint = $(this.sel.component);
    // setting sticky position on page load
    // if users refreshes page after they've scrolled
    this.setNav(switchPoint);

    $(window).on('scroll', () => {
      this.setNav(switchPoint);
    });

    this.jumpsPrevent();

    $('.nav-item.dropdown .nav-link').on('click', (e) => {
      e.preventDefault();
      if (window.matchMedia('(min-width: 992px)').matches) {
        const navItem = $(e.currentTarget).parent();
        if (navItem.hasClass('header__stickynav--open')) {
          $(this.sel.nav).removeClass('header--sticky-open');
          $('body').removeClass('modal-open');
          navItem.removeClass('header__stickynav--open');
        } else {
          $(this.sel.nav).addClass('header--sticky-open');
          $('body').addClass('modal-open');
          $(this.sel.navStickyOpen).removeClass('header__stickynav--open');
          navItem.addClass('header__stickynav--open');
        }
      }
    });

    $('.nav-link.dropdown-toggle').on('hidden.bs.dropdown', () => {
      if (window.matchMedia('(min-width: 992px)').matches) {
        if ($('.nav-item.dropdown .nav-link.show').length === 0) {
          $('body').removeClass('modal-open');
          $('.header__navigation').removeClass('header--sticky-open');
          $('.nav-item.dropdown .nav-link').parent().removeClass('header__stickynav--open');
        }
      }
    });
  }

  init() {
    if ($(this.sel.component).length <= 0) return;
    this.bindEvents();
    this.handleLevels();
    this.mobileNavMenu();
    this.helperBar();
    this.handleBack();
    this.selectedItem();
    this.selectedNavItem();
    this.stickyNav();
  }
}

export default new Header();
