class ReadMore {
  constructor() {
    this.sel = {
      component: '.textRow--read-more-btn'
    };
    this.bindEvents = this.bindEvents.bind(this);
    this.btnClick = this.btnClick.bind(this);
    this.init = this.init.bind(this);
  }

  bindEvents() {
    if ($(this.sel.component).parent().parent().height() > 2700) {
      $(this.sel.component).parent().addClass('textRow__read-more-intro');
      $(this.sel.component).removeClass('d-none');
      this.btnClick();
    } else {
      ($(this.sel.component)).parent().removeClass('textRow__read-more-intro');
      ($(this.sel.component)).addClass('d-none');
    }
  }

  btnClick() {
    $(this.sel.component).on('click', () => {
      $(this.sel.component).parent().removeClass('textRow__read-more-intro');
      $(this.sel.component).addClass('d-none');
    });
  }

  init() {
    if ($(this.sel.component).length <= 0) return;
    // Give time for DOM rendering before calculating height of container
    setTimeout(() => {
      this.bindEvents();
    }, 250);
  }
}

export default new ReadMore();
