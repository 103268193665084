
/*
<div class="toggle-parent" data-m-tabbed-panel data-m-tabbed-panel-settings='{"addClass": true}'>
  <a href="#" data-m-tabbed-panel-item="[data-itemOne]">Toggle item 1</a>
  <a href="#" data-m-tabbed-panel-item="#item2">Toggle item 2</a>
  <div class="item" data-itemOne>Item 1</div>
  <div class="item" id="item2">Item 2</div>
</div>
*/

class Toggle {
  constructor() {
    this.sel = {
      component: '[data-m-tabbed-panel]',
      items: '[data-m-tabbed-panel-item]',
      toggleClass: 'is-active',
      animate: false,
      addClass: false // only for lg-up
    };
    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  bindEvents(elem, moduleSettings) {
    this.settings = moduleSettings ? $.extend({}, this.sel, JSON.parse(moduleSettings)) : this.sel;
    const $elem = $(elem);
    const $item = $elem.find(this.sel.items);

    $item.on('click', (e) => {
      const attr = 'm-tabbed-panel-item';
      this.toggle(e.delegateTarget, attr);

      if (this.settings.addClass) {
        $elem.find(this.sel.items).not($(e.delegateTarget)).removeClass(this.settings.toggleClass).attr('aria-expanded', false);

        const $otherTargets = $elem.find(this.sel.items).not($(e.delegateTarget));

        $.each($otherTargets, (key, value) => {
          if ($(e.delegateTarget).data(attr) !== $(value).data(attr)) {
            if (this.settings.animate) {
              $elem.find($(value).data(attr)).collapse('hide');
            } else {
              $elem.find($(value).data(attr)).removeClass(this.settings.toggleClass);
            }
          }

          if ($(e.delegateTarget).data(attr) === $(value).data(attr)) {
            $(value).attr('aria-expanded', true).addClass(this.settings.toggleClass);
          }
        });
      }

      e.preventDefault();
    });

    if ($item.length === 0) {
      $elem.on('click', (e) => {
        this.toggle(e.delegateTarget, 'm-tabbed-panel', false);
        e.preventDefault();
      });
    }
  }

  toggle(target, attr) {
    const $module = $(this.sel.component);
    const targetAttr = $(target).data(attr);

    if (!window.matchMedia(window.pjs.breakpoints['lg-up']).matches && this.settings.addClass) {
      $(targetAttr).collapse('toggle');
      $(target).toggleClass(this.settings.toggleClass);
      this.toggleAttr(target, 'aria-expanded');
    } else if (this.settings.addClass !== true) {
      $(target).toggleClass(this.settings.toggleClass);
      this.toggleAttr(target, 'aria-expanded');
    } else {
      $(target).addClass(this.settings.toggleClass);
      $(target).attr('aria-expanded', true);
    }

    if (targetAttr && $(targetAttr).length > 0) {
      if (this.settings.addClass !== true) {
        $module.find(targetAttr).toggleClass(this.settings.toggleClass);
      } else if (this.settings.animate) {
        $module.find(targetAttr).collapse('show');
      } else {
        $module.find(targetAttr).addClass(this.settings.toggleClass);
      }
    }
  }

  toggleAttr(elem, attribute) {
    // true or false only
    $(elem).attr(attribute, (i, attr) => {
      return attr === 'true' ? 'false' : 'true';
    });
  }

  init() {
    if ($(this.sel.component).length <= 0) return;
    for (let i = 0; i < $(this.sel.component).length; i++) {
      const settings = $($(this.sel.component)[i]).attr('data-m-tabbed-panel-settings');
      this.bindEvents($(this.sel.component)[i], settings);
    }
  }
}

export default new Toggle();
