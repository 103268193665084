import Player from '@vimeo/player';

class VideoVimeoPlayer {
  constructor() {
    this.sel = {
      component: '.video',
      button: '.video__placeholder-playbutton',
      img: '.video__placeholder-image',
      placeholder: '.video__placeholder',
      videoPlayer: '.video__videoplayer',
      iframe: '.video__iframe'
    };

    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.videoPlayer = this.videoPlayer.bind(this);
  }

  bindEvents() {
    $(this.sel.button).on('click', (e) => {
      // Animate in the videoplayer
      $(e.target).closest('.video').find(this.sel.videoPlayer).animate({
        opacity: 1
      }, 250, () => {
        // Show
        $(e.target).closest('.video').find(this.sel.videoPlayer).show();
      });

      // Animate out the preview-image div
      $(e.target).closest(this.sel.placeholder).animate({
        opacity: 0
      }, 250, () => {
        // Hide the preview-image
        $(e.target).closest(this.sel.placeholder).hide();
      });

      // pause other videos on the page if they are playing
      const currentId = $(e.target).closest('.video').data('video-id');
      window.players.forEach((player) => {
        player.pause();
      });
      window[`player${currentId}`].play();
    });

    if ($('.videoSeries__seriesWindow').length > 0) {
      // Reveal new video window
      $('.videoSeries__seriesWindow').find('button').on('click', (z) => {
        window.players.forEach((player) => {
          player.pause();
        });
        // eslint-disable-next-line max-len
        // NB. Only update the visibility of videos in the video series that the button is contained within
        const $series = $(z.target).closest('.videoSeries');
        const id = $(z.target).data('video-id');
        $series.find('.video').addClass('d-none');
        $series.find('.videoCaption').addClass('d-none');
        $series.find(`[data-video-id=${id}]`).removeClass('d-none');
        $series.find(`[data-caption-id=${id}]`).removeClass('d-none');
        $series.find('.videoSeries__videoWindow')[0].scrollIntoView();
      });
    }
  }

  createPlayer(videoId, uid) {
    // Param reference: https://developer.vimeo.com/player/sdk/embed
    const params = $(`[data-video-id=${videoId}]`);
    let url = `https://player.vimeo.com/video/${videoId}`;
    if (params.data('video-hash-key')) {
      url = url.concat(`?h=${params.data('video-hash-key')}`);
    }
    window[`player${videoId}`] = new Player(uid, {
      url,
      related: params.data('related') ? params.data('related') : false,
      muted: params.data('muted') ? params.data('muted') : false,
      autoplay: params.data('autoplay') ? params.data('autoplay') : false,
      dnt: params.data('cookies') ? params.data('cookies') : true
    });
    window.players.push(window[`player${videoId}`]);
    window[`player${videoId}`].on('ended', () => {
      this.resetVideo(videoId);
    });
  }

  resetVideo(videoId) {
    $(this.sel.button).closest('.video').find(this.sel.videoPlayer).animate({
      opacity: 0
    }, 250, () => {
      // Hide video player
      $(this.sel.button).closest('.video').find(this.sel.videoPlayer).hide();
    });

    // Animate in the preview-image div
    $(this.sel.button).closest(this.sel.placeholder).animate({
      opacity: 1
    }, 250, () => {
      // Show the preview-image
      $(this.sel.button).closest(this.sel.placeholder).show();
    });
    // Destroy and re-create the video player
    window[`player${videoId}`].destroy();
    this.videoPlayer();
  }

  videoPlayer() {
    $(this.sel.component).each((index, element) => {
      if ($(element).data('video-player') === 'vimeo-player') {
        const videoId = $(element).data('video-id');
        const uid = $(element).data('video-uid');
        this.createPlayer(videoId, uid);
      }
    });
  }

  init() {
    if ($(this.sel.component).length <= 0) return;
    this.videoPlayer();
    this.bindEvents();
  }
}
export default new VideoVimeoPlayer();
