import Google from './Google';
import GoogleMap from './GoogleMap';
import LocationsHelpers from './LocationsHelpers';
import LocationsAccordion from './LocationsAccordion';
import LocationSelector from './LocationSelector';

class GoogleGeoCode {
  init(selector, endpoint, key) {
    this.key = key;
    if ($(`#${selector}`).length <= 0) return;

    LocationsHelpers.getLocations(endpoint).then(locations => {
      this.locations = locations;
      GoogleMap.init(selector, false, this.key, this.locations);
      LocationsAccordion.init();
      LocationSelector.init('geoCode-location-selector', this.key);
      // Set initial state using URL params and/or session store
      const url = new URL(window.location);
      const urlCode = url.searchParams.get('location');
      const sessionCode = sessionStorage.getItem('location');
      const code = urlCode ? urlCode : sessionCode;
      if (code) {
        this.syncComponents(code);
      }
    }).catch(e => {
      throw new Error(e);
    });

    // Intercept push to history.pushState - method to detect change to geo location
    /* eslint func-names: "off" */
    const _this = this;
    (function (history) {
      const pushState = history.pushState;
      history.pushState = function (state) {
        if (typeof history.onpushstate === 'function') {
          history.onpushstate({state: state});
        }

        if (arguments[0].hasOwnProperty('location')) {
          _this.syncComponents(arguments[0].location);
        }

        return pushState.apply(history, arguments);
      };
    })(window.history);

    $(document).on('location-pin-clicked', (event, clickedLocation)=>{
      const locationIndex = this.locations.map(location => location.title).indexOf(clickedLocation.title);
      LocationsAccordion.showAccordion(locationIndex);
    });
  }

  /**
   * Sync page components to the same country code
   * @param {string} countryCode 2 digit country code
   * @returns {undefined}
   */
  syncComponents(countryCode) {
    if (countryCode) {
      this.getNearestJWLocation(countryCode).then(jwLocation => {
        LocationSelector.setDropdownLocation(countryCode);
        LocationsAccordion.showAccordion(jwLocation.mapIndex);
        if (!jwLocation.isInternational) {
          setTimeout(() => GoogleMap.clickMarker(jwLocation.mapIndex), 500);
        } else {
          setTimeout(() => GoogleMap.hideInfoWindow(), 500);
        }
      });
    } else {
      LocationsAccordion.showAllAccordions();
      GoogleMap.hideInfoWindow();
    }
  }

  /**
   * If countryCode matches a James Walker location, return as a James Walker object
   * If country code doesn't match a James Walker location, find nearest James Walker location and return that
   * Return object is safe to use controlling elements loaded from endpoint object
   * @param {string} countryCode 2 digit country code
   * @returns {Promise} resolve James Walker location object
   */
  getNearestJWLocation(countryCode) {
    let jwLocation = false;
    return new Promise((resolve) => {
      for (let i = 0; i < this.locations.length; i++) {
        if (this.locations[i].hasOwnProperty('serves')) {
          for (let z = 0; z < this.locations[i].serves.length; z++) {
            // const currentValue = this.locations[i].serves[z].value;
            if (this.locations[i].serves[z].value === countryCode) {
              jwLocation = true;
              resolve({
                countryCode: this.locations[i].serves[0].value,
                mapIndex: i,
                isInternational: this.locations[i].isInternational
              });
            }
          }
        }
      }
      if (!jwLocation) {
        // this.findNearest().then(locationIndex => {
        //   resolve({
        //     countryCode: this.locations[locationIndex].serves[0].value,
        //     mapIndex: locationIndex
        //   });
        // });
      }
    });
  }

  /**
   * Find the closest destination to the users current known location
   * @param {object} location Device location
   * @returns {Promise<unknown>} Returns distance and index position of known locations
   */
  findNearest() {
    return new Promise((resolve) => {
      const deviceLocation = JSON.parse(sessionStorage.getItem('device_location'));
      Google.get(this.key).then(google => {
        let destinations = [];
        this.locations.forEach((loc) => {
          destinations.push(new google.maps.LatLng(loc.lat, loc.lng));
        });

        const matrix = new google.maps.DistanceMatrixService();
        matrix.getDistanceMatrix({
          origins: [new google.maps.LatLng(String(deviceLocation.latitude), String(deviceLocation.longitude))],
          destinations: destinations,
          travelMode: google.maps.TravelMode.DRIVING
        }).then(res => {
          let dist = {
            distance: false,
            index: 0
          };

          if (res && res.hasOwnProperty('rows') && res.rows.length > 0) {
            res.rows[0].elements.forEach((destination, i) => {
              if (destination.hasOwnProperty('distance')) {
                if (!dist.distance || destination.distance.value < dist.distance) {
                  dist.distance = destination.distance.value;
                  dist.index = i;
                }
              }
            });
          }
          resolve(dist.index);
        });
      });
    });
  }
}

export default new GoogleGeoCode();
