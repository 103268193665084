import Google from './Google';

class LocationSelector {
  init(selector, key) {
    this.selector = selector;
    this.key = key;
    if ($(`#${this.selector}`).length <= 0) return;

    $(`#${this.selector}`).bind('change', (event) => {
      this.setState(event.target.value);
    });

    $('#locationSelect-getMyLocation').bind('click', () => {
      this.getDeviceLocation();
    });
  }

  /**
   * Called externally
   * Change drop down selector to match state on state change
   * Occurs if state changes by not using drop down selector
   * @param {string} countryCode 2 digit country code
   * @returns {undefined}
   */
  setDropdownLocation(countryCode) {
    if (countryCode !== $(`#${this.selector} :selected`).val()) {
      $(`#${this.selector} [value="${countryCode}"]`).attr('selected', 'selected');
      $(`#${this.selector}`).selectric('refresh');
    }
  }

  /**
   * Best effort attempt to get devices current location
   * @returns {callback} getPosition
   */
  getDeviceLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getDevicePosition.bind(this));
    }
  }

  /**
   * Convert position object into a useful (human readable) country location
   * @param {object} position navigator.geolocation object
   * @returns {promise} resolve device location
   */
  getDevicePosition(position) {
    const latlng = {
      lat: parseFloat(position.coords.latitude),
      lng: parseFloat(position.coords.longitude)
    };
    Google.get(this.key).then(google => {
      new google.maps.Geocoder().geocode({location: latlng}).then(res => {
        let deviceLocation = {
          countryCode: false,
          countryName: false,
          place_id: false,
          latitude: 0,
          longitude: 0
        };
        if (res && res.results && res.results.length > 0) {
          for (let i = 0; i < res.results.length; i++) {
            if (res.results[i].address_components) {
              for (let z = 0; z < res.results[i].address_components.length; z++) {
                if (res.results[i].address_components[z].hasOwnProperty('short_name') && res.results[i].address_components[z].hasOwnProperty('types')) {
                  if (res.results[i].address_components[z].types.includes('country')) {
                    // Device location object
                    deviceLocation = {
                      countryCode: res.results[i].address_components[z].short_name,
                      countryName: res.results[i].address_components[z].long_name,
                      place_id: res.results[i].place_id,
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude
                    };
                  }
                }
              }
            }
          }
        }
        this.storeDeviceLocation(deviceLocation);
      });
    });
  }

  /**
   * Stores device location, applies visual indicator and updates state
   * @param {object} deviceLocation device location object
   * @return {undefined}
   */
  storeDeviceLocation(deviceLocation) {
    sessionStorage.setItem('device_location', JSON.stringify(deviceLocation));
    this.setState(deviceLocation.countryCode);
  }

  setState(countryCode) {
    const url = new URL(window.location);
    if (countryCode) {
      url.searchParams.set('location', countryCode);
      sessionStorage.setItem('location', countryCode);
      window.history.pushState({ location: countryCode }, '', url);
    } else {
      url.searchParams.delete('location');
      sessionStorage.setItem('location', '');
      window.history.pushState({ location: '' }, '', url);
    }
  }
}

export default new LocationSelector();
