import Google from './Google';
import LocationsHelpers from './LocationsHelpers';

class GoogleMapStatic {
  /**
   * Set default map options
   */
  static get mapOptions() {
    return {
      center: { lat: 0, lng: 0 },
      disableDefaultUI: true,
      isFractionalZoomEnabled: false,
      scrollwheel: false,
      zoom: 16,
      minZoom: 16,
      maxZoom: 16,
      draggableCursor: 'arrow',
      gestureHandling: 'none'
    };
  }

  /**
   * Set map style
   */
  static get mapStyle() {
    return [
      {
        'featureType': 'all',
        'elementType': 'geometry.fill',
        'stylers': [
          {
            'visibility': 'on'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'geometry.stroke',
        'stylers': [
          {
            'visibility': 'on'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'labels',
        'stylers': [
          {
            'visibility': 'simplified'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'labels.text'
      },
      {
        'featureType': 'all',
        'elementType': 'labels.text.stroke',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'administrative',
        'elementType': 'geometry',
        'stylers': [{
          'visibility': 'off'
        }]
      },
      {
        'featureType': 'administrative.country',
        'elementType': 'geometry.stroke',
        'stylers': [{
          'visibility': 'on'
        }]
      },
      {
        'featureType': 'administrative.province',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'administrative.locality',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'administrative.neighborhood',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'administrative.land_parcel',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'landscape',
        'elementType': 'all',
        'stylers': [
          {
            'color': '#efefef'
          },
          {
            'visibility': 'on'
          }
        ]
      },
      {
        'featureType': 'landscape',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'landscape',
        'elementType': 'labels.text.stroke',
        'stylers': [
          {
            'visibility': 'off'
          },
          {
            'color': '#a21d1d'
          }
        ]
      },
      {
        'featureType': 'landscape.man_made',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'poi',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'on'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'geometry',
        'stylers': [
          {
            'visibility': 'on'
          }
        ]
      },
      {
        'featureType': 'transit',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'all',
        'stylers': [
          {
            'color': '#cad2d3'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels',
        'stylers': [
          {
            'color': '#6e6e6e'
          },
          {
            'weight': '0.5'
          },
          {
            'visibility': 'simplified'
          },
          {
            'invert_lightness': true
          }
        ]
      }
    ];
  }

  constructor() {}

  init(selector, endpoint, key) {
    if ($(`#${selector}`).length <= 0) return;
    LocationsHelpers.getLocations(endpoint).then(res => {
      this.loadMap(selector, res, key);
    });
  }

  loadMap(selector, location, key) {
    /**
     * Adjust map options based on viewable width (zoom, center)
     */

    let mapOptions = GoogleMapStatic.mapOptions;
    Google.get(key)
      .then((google) => {
        /**
         * Init map
         */
        /* eslint new-cap: "off" */
        /* eslint  no-new: "off" */
        const lngOffset = location.lng - 0.003;
        mapOptions.center = { lat: location.lat, lng: lngOffset };
        const map = new google.maps.Map(document.getElementById(selector), mapOptions);

        /**
         * Apply map styling
         */
        const styledMapType = new google.maps.StyledMapType(GoogleMapStatic.mapStyle);
        map.mapTypes.set('james_walker_style', styledMapType);
        map.setMapTypeId('james_walker_style');

        const myLatlng = new google.maps.LatLng(location.lat, location.lng);

        new google.maps.Marker({
          position: myLatlng,
          map: map,
          title: location.title,
          icon: LocationsHelpers.markerImage
        });
      }).catch(e => {
        throw new Error(e);
      });
  }
}

export default new GoogleMapStatic();
