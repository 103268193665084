import Google from './Google';
import LocationsHelpers from './LocationsHelpers';

class GoogleMap {
  /**
   * Set map boundary, restricting scrollable area
   */
  static get mapBoundary() {
    return {
      north: 72,
      south: -45,
      west: -145,
      east: 180
    };
  }

  /**
   * Set default map options
   */
  static get mapOptions() {
    return {
      center: { lat: 20, lng: 20 },
      disableDefaultUI: true,
      isFractionalZoomEnabled: true,
      zoom: 2.4,
      minZoom: 2.4,
      maxZoom: 4.4,
      gestureControl: 'cooperative'
    };

    /* restriction: {
      latLngBounds: GoogleMap.mapBoundary,
        strictBounds: true
    }, */
  }

  /**
   * Set map style
   */
  static get mapStyle() {
    return [
      {
        'featureType': 'all',
        'elementType': 'geometry.fill',
        'stylers': [
          {
            'visibility': 'on'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'geometry.stroke',
        'stylers': [
          {
            'visibility': 'on'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'labels',
        'stylers': [
          {
            'visibility': 'simplified'
          },
          {
            'color': '#6e6e6e'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'labels.text',
        'stylers': [
          {
            'color': '#6e6e6e'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'labels.text.stroke',
        'stylers': [
          {
            'color': '#6e6e6e'
          },
          {
            'visibility': 'on'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'administrative',
        'elementType': 'geometry',
        'stylers': [{
          'visibility': 'off'
        }]
      },
      {
        'featureType': 'administrative.country',
        'elementType': 'geometry.stroke',
        'stylers': [{
          'visibility': 'on'
        }]
      },
      {
        'featureType': 'administrative.province',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'administrative.locality',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'administrative.neighborhood',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'administrative.land_parcel',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'landscape',
        'elementType': 'all',
        'stylers': [
          {
            'color': '#efefef'
          },
          {
            'visibility': 'on'
          }
        ]
      },
      {
        'featureType': 'landscape',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'landscape',
        'elementType': 'labels.text.stroke',
        'stylers': [
          {
            'visibility': 'off'
          },
          {
            'color': '#a21d1d'
          }
        ]
      },
      {
        'featureType': 'landscape.man_made',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'poi',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'geometry',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'transit',
        'elementType': 'all',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'all',
        'stylers': [
          {
            'color': '#cad2d3'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels',
        'stylers': [
          {
            'color': '#6e6e6e'
          },
          {
            'weight': '1.00'
          },
          {
            'visibility': 'simplified'
          },
          {
            'invert_lightness': true
          }
        ]
      }
    ];
  }

  constructor() {
    this.markers = [];
    this.google = false;
  }

  init(selector, endpoint, key, locations = false) {
    if ($(`#${selector}`).length <= 0) return;
    this.getLocations(endpoint, locations).then(locationsObj => {
      this.loadMap(selector, locationsObj, key);
    });
  }

  getLocations(endpoint, locations) {
    if (endpoint === false && locations !== false) {
      return Promise.resolve(locations);
    }
    return LocationsHelpers.getLocations(endpoint);
  }

  loadMap(selector, locations, key) {
    /**
     * Adjust map options based on viewable width (zoom, center)
     */

    let mapOptions = GoogleMap.mapOptions;
    const windowWidth = window.innerWidth;
    if (windowWidth <= 400) {
      mapOptions.zoom = 1.0;
      mapOptions.minZoom = 1.0;
    } else if (windowWidth <= 700) {
      mapOptions.zoom = 1.5;
      mapOptions.minZoom = 1.5;
    } else if (windowWidth <= 950) {
      mapOptions.zoom = 1.8;
      mapOptions.minZoom = 1.8;
    } else if (windowWidth <= 1150) {
      mapOptions.zoom = 1.9;
      mapOptions.minZoom = 1.9;
    }

    Google.get(key)
      .then((google) => {
        this.google = google;
        /**
         * Init map
         */
        /* eslint new-cap: "off" */
        /* eslint  no-new: "off" */
        const map = new google.maps.Map(document.getElementById(selector), mapOptions);

        /**
         * Apply map styling
         */
        const styledMapType = new google.maps.StyledMapType(GoogleMap.mapStyle);
        map.mapTypes.set('james_walker_style', styledMapType);
        map.setMapTypeId('james_walker_style');

        /**
         * Create info windows for each marker location
         * @type {google.maps.InfoWindow}
         */
        this.infoWindow = new google.maps.InfoWindow();
        const markers = [];

        locations.forEach(location => {
          const myLatlng = new google.maps.LatLng(location.lat, location.lng);

          const marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            title: location.title,
            icon: LocationsHelpers.markerImage
          });

          this.markers.push(marker);
          const options = {
            disableAutoPan: false,
            infoBoxClearance: new google.maps.Size(1, 1),
            isHidden: location.isInternational,
            pane: 'floatPane',
            enableEventPropagation: false
          };

          function formatContentArray(arr) {
            let container = '<span class=\'map--infoBoxContentItems\'>';
            arr.forEach(itemText => {
              container += `<span class='map--infoBoxContentItem'> ${itemText} </span>`;
            });
            container += '</span>';
            return container;
          }

          /* eslint no-shadow: "off", func-names: "off" */
          (function (marker, location, infoWindow) {
            let servesHtml = formatContentArray(location.servesOverride.split('\r\n'));
            if (!location.servesOverride)  {
              servesHtml = formatContentArray(location.serves.map(item => item.text));
            }
            google.maps.event.addListener(marker, 'click', () => {
              infoWindow.setContent('<div class=\'map--infoBox\'>' +
                `<span class='map--infoBoxTitle'> ${location.title} </span>` +
                `<span class='map--infoBoxContentTitle'>${location.countriesServedLabel}</span>` +
                `${servesHtml}` +
                '<a class=\'map--btn button button--right-icon-arrow\'' +
                `rel='${location.button.rel}'` +
                `target='${location.button.target}'` +
                `href='${location.button.url}'` +
                `>${location.button.label}</a>` +
                '</div>'
              );
              $(document).trigger('location-pin-clicked', location);

              infoWindow.setOptions(options);
              infoWindow.open(map, marker);
            });
          })(marker, location, this.infoWindow);
          markers.push(marker);
        });
        google.maps.event.addListener(map, 'click', () => {
          this.infoWindow.close();
        });
      }).catch(e => {
        throw new Error(e);
      });
  }
  clickMarker(i) {
    try {
      if (this.google) {
        this.google.maps.event.trigger(this.markers[i], 'click');
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  hideInfoWindow() {
    this.infoWindow.close();
  }
}

export default new GoogleMap();
