class FullList {
  constructor() {
    this.sel = {
      component: '.fullList',
      specialTabs: '[data-tab-type="full-list-special"]',
      filterTagsContainer: '[class*=applied-filters] .container'
    };

    this.init = this.init.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.renderSelectedCategories = this.renderSelectedCategories.bind(this);
  }

  bindEvents() {
    // Close search tab on click within window scope
    $(window).click(() => {
      $('[class*="special-tab-content"]').removeClass('d-block');
    });

    // Don't propegate clicks inside search tab to window scope so clicks within don't close it
    $(this.sel.component).find('[class*="special-tab-content"]').on('click', (e) => {
      if (e.target.nodeName !== 'BUTTON') {
        e.stopPropagation();
      }
    });

    // Only allow 1 item to be selected
    $('input').click(function () {
      $('input').not(this).prop('checked', false);
    });

    $(this.sel.component).find('.tabbed-panel__link').on('click', (e) => {
      // Don't propegate clicks on links to window scope
      e.stopPropagation();
      $(e.currentTarget).siblings('.is-active').removeClass('is-active');
      $(e.currentTarget).addClass('is-active');

      $('[class*="special-tab-content"]').removeClass('d-block');

      if ($(e.currentTarget).data('tab-type') === 'full-list-special') {
        let contentName = $(e.currentTarget).data('tab');
        $(`[data-content="${contentName}"]`).addClass('d-block');
        $(`[data-content="${contentName}"]`).find('input')[0].focus();
      }
    });
  }

  queryParamsToArray(filterParamName) {
    return Array.from(new URL(window.location.href).searchParams)
      .filter(param => param[0].toLowerCase() === filterParamName.toLowerCase())
      .map(param => param[1]); // param objects come back as ['key', 'value'] so this taking the values
  }

  renderSelectedCategories() {
    let filterTagsContainer = $(this.sel.filterTagsContainer);
    let filterParamName = filterTagsContainer.parent().data('filterparam');

    if (typeof filterParamName === 'undefined' || !filterParamName.length) {
      return;
    }

    // get values from url
    let selectedTags = this.queryParamsToArray(filterParamName);

    // populate container with data-target spans
    if (selectedTags.length > 0) {
      selectedTags.forEach(selectedTag => {
        let label = $('input[type=checkbox][value="' + selectedTag + '"]+label');
        let input = $('input[type=checkbox][value="' + selectedTag + '"]');

        if (!label.length || !input.length) {
          return;
        }

        let tagName = label.text();
        let inputId = input.attr('id');
        let tagType = input.parent().data('group');

        if (!tagName.length || typeof inputId === 'undefined' || typeof tagType === 'undefined') {
          return;
        }

        let tag =
        `<div class="selected-tag selected-tag--${tagType}" data-target="${inputId}">
          <span class="selected-tag__close"></span> ${tagName}
        </div>`;

        filterTagsContainer.append(tag);

        // re-check the selected filters inputs
        // so that if user removes one, form re-sumbits correctly
        input.prop('checked', true);
      });
    }

    // on close button click
    $('.selected-tag__close').on('click', (e) => {
      let inputID = $(e.currentTarget).parent().data('target');
      $(`input#${inputID}`).prop('checked', false);
      $(`input#${inputID}`).closest('#filter-form').submit();
    });

    $('#filter-form > div > button[type=reset]').on('click', (e) => {
      $('input').prop('checked', false);
      $(e.currentTarget).closest('form').submit();
    });
  }

  init() {
    if (this.sel.component.length <= 0) return;
    this.bindEvents();
    this.renderSelectedCategories();
  }
}

export default new FullList();
