class InPageNavigation {
  constructor() {
    this.sel = {
      component: '.inPageNavigation',
      list: '.inPageNavigation__items-list',
      items: '.inPageNavigation__items-list a'
    };

    this.data = {
      sectionSelector: 'data-sections-selector'
    };

    this.init = this.init.bind(this);
    this.navSticky = this.navSticky.bind(this);
    this.generateLinkList = this.generateLinkList.bind(this);
  }

  generateLinkList() {
    const $sections = $($(this.sel.component).attr(this.data.sectionSelector));

    let checkExist = setInterval(() => {
      if ($sections.length > 0) {
        clearInterval(checkExist);

        $sections.toArray().forEach(sectionHeader => {
          if (!$(sectionHeader).attr('id')) {
            const randomInt = Math.floor(Math.random() * 65500);
            $(sectionHeader).attr('id', 'sectionHeader' + randomInt);
          }

          let title = $(sectionHeader).text();
          let id = $(sectionHeader).attr('id');
          let link = `<a class="small-body--bold" href="#${id}">${title}</a>`;
          $(this.sel.list).append(link);
        });

        $(this.sel.items).on('click', (e) => {
          e.preventDefault();
          let targetSection = $(e.currentTarget).attr('href');
          this.scrollToSection(targetSection);
        });
      } else {
        clearInterval(checkExist);
      }
    }, 100);
  }

  setNav() {
    if (window.scrollY >=
        (($(this.sel.component).prev().offset().top + $(this.sel.component).prev().outerHeight()) - $('.header__navigation').outerHeight())) {
      $(this.sel.component).addClass('inPageNavigation--sticky');
      $(this.sel.component).css('top', $('.header__navigation').height());
    } else {
      $(this.sel.component).removeClass('inPageNavigation--sticky');
    }

    if ((window.scrollY + $(window).height()) >= $('.footer').offset().top) {
      $(this.sel.component).removeClass('inPageNavigation--sticky');
    }
  }

  // sticky on scroll - offset from main navigation
  navSticky() {
    // setting sticky position on page load
    // if user refrehes page after he has scrolled
    this.setNav();

    $(window).on('scroll', () => {
      this.setNav();
    });

    this.jumpsPrevent();
  }

  jumpsPrevent() {
    $(this.sel.component).after('<div class="jumps-prevent-inpagenav"></div>');
    // const stickyHeight = $(this.sel.component).innerHeight();
    // for some reason it has to match the main nav values
    const stickyHeight = $('.header__navigation').innerHeight();
    $(this.sel.component).css({'margin-bottom': '-' + stickyHeight + 'px'});
    $(this.sel.component).next().css({'padding-top': stickyHeight + 'px'});
  }

  // scroll to section with offset main nav + inpage nav
  scrollToSection(section) {
    const element = $(section);
    const headerOffset = $(this.sel.component).height() + $('.header__navigation').height();
    const elementPosition = element.position().top;
    const offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  init() {
    if ($(this.sel.component).length <= 0) return;
    this.generateLinkList();
    this.navSticky();
  }
}

export default new InPageNavigation();
