class Selectric {
  constructor() {
    this.sel = {
      component: 'select',
      form: 'form'
    };

    this.init = this.init.bind(this);
    this.initSelectric = this.initSelectric.bind(this);
    this.dispatchChange = this.dispatchChange.bind(this);
  }

  initSelectric() {
    // Init the selectric plugin
    $(this.sel.component).selectric();
    // Find each selectric input
    $(this.sel.form).find('.selectric-input').each((index, element) => {
      // Get the label for this dropdown
      const label = $(element).parent().find('.selectric-hide-select select').attr('title');
      // Add the label as the title attribute
      $(element).attr('title', label);
    });
  }

  dispatchChange() {
    $(this.sel.form).find('.selectric-items li').on('click', (e) => {
      const $select = $(e.currentTarget).parents('.selectric-wrapper').find('select');

      $select.get(0).dispatchEvent(new Event('change'));
    });
  }

  init() {
    if ($(this.sel.component).length <= 0) return;
    this.initSelectric();
    this.dispatchChange();
  }
}

export default new Selectric();
