class LocationsHelpers {
  get markerImage() {
    return `${window.assetPath}/img/icons/map-marker.svg`;
  }

  getLocations(endpoint) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.open('GET', endpoint);
      req.onload = () => {
        if (req.status === 200) {
          resolve(JSON.parse(req.response));
        } else {
          reject();
        }
      };
      req.send();
    });
  }
}

export default new LocationsHelpers();
